import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import usePopup from "hooks/usePopup";
import { convertOrderStatus } from "../../helper";
import { useOrderActions } from "../../hooks";
import ConfirmShippment from "../../popup/ConfirmShippment";
import { memo } from "react";

const ActionColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: 80,
});

const ActionButton = styled(FormButton)({
    height: 25,
    width: 185,
    fontSize: 12,
    margin: "10px 6px 0px",
});

export default memo(({ data, refetch }) => {
    const popup = usePopup();
    const {
        downloadOrderInvoice,
        orderInvoiceLoading,
        downloadDeliveryNote,
        deliveryNoteLoading,
        downloadWaybill,
        waybillLoading,
    } = useOrderActions();

    const collections = {
        order_status: data.order_status,
        pay_status: data.pay_status,
        shipping_status: data.shipping_status,
        RefundRequests: data.RefundRequests,
    };

    return ["shipped", "to ship"].includes(convertOrderStatus(collections).toLowerCase()) ? (
        <ActionColumn>
            {convertOrderStatus(collections).toLowerCase() === "to ship" && (
                <ActionButton
                    height={25}
                    width={170}
                    ccolor="blue"
                    cvariant="contained"
                    onClick={() => popup(ConfirmShippment, { orderId: data.order_id, refetch })}
                >
                    Confirm Shipment
                </ActionButton>
            )}
            <ActionButton
                height={25}
                width={170}
                ccolor="blue"
                cvariant="outlined"
                disabled={orderInvoiceLoading}
                onClick={() => downloadOrderInvoice({ order_id: data.order_id, order_sn: data.order_sn })}
            >
                {orderInvoiceLoading ? "Downloading . . . " : "Download invoice"}
            </ActionButton>
            <ActionButton
                height={25}
                width={170}
                ccolor="blue"
                cvariant="outlined"
                disabled={deliveryNoteLoading}
                onClick={() => downloadDeliveryNote({ order_id: data.order_id, order_sn: data.order_sn })}
            >
                {deliveryNoteLoading ? "Downloading . . . " : "Download Delivery Note"}
            </ActionButton>
            <ActionButton
                height={25}
                width={170}
                ccolor="blue"
                cvariant="outlined"
                disabled={waybillLoading}
                onClick={() => downloadWaybill({ order_id: data.order_id, order_sn: data.order_sn })}
            >
                {waybillLoading ? "Downloading . . . " : "Download Waybill"}
            </ActionButton>
        </ActionColumn>
    ) : (
        <div></div>
    );
});
