import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    styled,
    Typography,
} from "@material-ui/core";
import {
    FormButton,
    FormCategory,
    FormTitle,
    YHLogo,
    YohoCheckbox,
    YohoDatepicker,
    YohoFileupload,
    YohoRadio,
    YohoSelect,
    YohoBrandFileUpload,
} from "components/Global";
import { ButtonLink } from "components/Global/Form";
import InputText from "components/Global/Form/InputText";
import storage from "helper/persist-storage";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterMerchantData, getSubDistrict } from "service/actions/authentication";
import { InputAdornment } from "@material-ui/core";
import { getAreaByCountry, setSnackbar } from "service/actions/site";
import BankField from "components/Global/Form/BankField";
import AdditionalContact from "components/Global/Form/AdditionalContact";

const RegisterContainer = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
});

const RegisterHead = styled("div")(({ theme }) => ({
    padding: `0 20px`,
    height: "56px",
    display: "flex",
    justifyContent: "space-between",
    "& *": {
        alignItems: "center",
    },
    borderBottom: "1px solid #BDBDBD",
}));

const RegisterFormLogo = styled("div")({
    display: "flex",
    gap: "15px",
});

const TitleText = styled("h4")({
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "26px",
    margin: 0,
});

const RegisterContent = styled("div")(({ theme }) => ({
    padding: 25,
    maxWidth: 1280,
    margin: "auto",
}));

const RegisterFoot = styled("div")(({ theme }) => ({
    borderTop: "1px solid #BDBDBD",
    padding: 25,
    maxWidth: 1280,
    margin: "auto",
    "& .form-group": {
        width: "100%",
        "& .MuiFormControlLabel-root": {
            width: "100%",
        },
    },
}));

const RegisterFormTitle = styled("h1")({
    textAlign: "center",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "69.5px",
    color: "#424242",
});

const AgreementFormGroup = styled(FormGroup)({
    "& > .MuiFormControlLabel-root": {
        margin: "8px 0",
        cursor: "unset",
        "& > *:first-child": {
            cursor: "pointer",
        },
    },
});

const AgreementContent = styled("div")({
    display: "inline",
    flexWrap: "wrap",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "34px",
    "& > a": {
        textDecoration: "none",
    },
});

const AgreementLink = styled("div")(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: "0 5px",
    whiteSpace: "nowrap",
    display: "inline",
}));

const RegisterButtonContainer = styled("div")({
    display: "flex",
    gap: "20px",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "20px 0",
    justifyContent: "center",
});

const optionPlaceHolder = {
    areas: [{ id: 1, name: "Hongkong Island" }],
    countries: [{ id: 1, name: "Afghanistan", code: "AF" }],
    personal_titles: [{ id: 1, name: "Mr" }],
    international_codes: [{ id: 1, code: "AF", name: "Afghanistan", dial_code: "+93" }],
    departments: [{ id: 1, name: "Sales" }],
    categories: [
        {
            id: 1,
            name: "Consumer Electronics",
            sub_categories: [{ id: 1, name: "Mobile & Accessories", category_id: 1 }],
        },
        {
            id: 2,
            name: "Beauty & Health",
            sub_categories: [{ id: 6, name: "Face", category_id: 2 }],
        },
    ],
    product_sales: [{ id: 1, amount_range: "1 - 9" }],
    company_size: [{ id: 1, size: "1-25" }],
};

const online_experience = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
];

const Agreement = [
    {
        id: 1,
        name: "agreement-1",
        label: (
            <AgreementContent>
                * I agree to the{" "}
                <a href="https://www.yohohongkong.com/post/1064-Yoho-Marketplace-Terms-of-Service" target="_blank">
                    <AgreementLink>YOHO Marketplace Terms of Service</AgreementLink>
                </a>
                on behalf of my company .
            </AgreementContent>
        ),
    },
    {
        id: 2,
        name: "agreement-2",
        label: (
            <AgreementContent>
                {" "}
                I agree to accept the information provided by YOHO for the purpose of business development and
                promotion.
            </AgreementContent>
        ),
    },
];

const default_subdistrict = [
    {
        id: 1,
        name: "undefined",
        district_id: undefined,
    },
];

const defaultValues = {
    "agreement-1": true,
    "agreement-2": true,
    document_url: "",
    document_brand: "",
    company_name_english: "",
    company_name_chinese: "",
    company_email_address: "",
    company_description: "",
    floor_room: "",
    street_building: "",
    country_id: { id: 3409, name: "Hong Kong" },
    area_id: "",
    sub_district_id: "",
    certificate_number: "",
    expired_date: "2022-12-07",
    personal_title_id: "",
    last_name: "",
    first_name: "",
    personal_email: "",
    contact_international_code_id: "",
    contact_number: "",
    contact_ext: "",
    personal_position: "",
    company_size: "",
    department_ids: [],
    category_ids: {
        "id-1": [],
        "id-23": [],
        "id-44": [],
        "id-171": [],
        "id-482": [],
        "id-500": [],
        "id-521": [],
        "id-542": [],
        "id-568": [],
        "id-603": [],
    },
    store_name_english: "",
    store_name_chinese: "",
    store_product_sales_id: "",
    store_online_experience: false,
    additional_contacts: [],
};

const checkNoEmoji = (value) => {
    // const emojiRegex = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/g;
    // if (emojiRegex.test(value)) {
    //     return "This field cannot contains emoji";
    // }
    // *** there currently no reliable way to do this ***
    return true;
};

const validateWishPercentage = (value) => {
    if (!/^[0-9]+$/.test(value)) {
        return "This field allows integer number only";
    }
    if (!/^([1-9][0-9]?|100)$/.test(value)) {
        return "Only percentages between 1% and 100% are allowed.";
    }
    return true;
};

// TO-DO: Redux option list cannot figure out, if found can be combined
const optionListNotInOptionsData = {
    company_size: [
        { id: 1, size: "1-24" },
        { id: 2, size: "25-49" },
        { id: 3, size: "50-99" },
        { id: 4, size: "100 or above" },
    ],
};

function RegisterForm() {
    const optionsData = useSelector((state) => state.site.master);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, control, reset, watch, setError, clearErrors } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues,
        shouldUnregister: false,
    });
    const [formReady, setFormReady] = useState(false);

    useEffect(() => {
        storage.getItem("draft-newmerchant").then((val) => {
            if (val) reset(val);
            setFormReady(true);
        });
    }, []);

    const {
        areas,
        countries,
        personal_titles,
        international_codes,
        departments,
        categories,
        subdistrict,
        product_sales,
    } = Object.keys(optionsData).length > 0 ? optionsData : optionPlaceHolder;
    const { current: currentValue } = control.defaultValuesRef;
    const [submitClicked, setSubmitClicked] = useState(false);

    const handleSubmitted = () => {
        setSubmitClicked(true);
    };

    const onSubmit = (data) => {
        // Due to some bug non-empty check for bank name need to be performed here
        if (!data.bank_name) {
            setError("bank_name", { message: "This field cannot be empty" });
            return;
        }

        const agreements = Agreement.filter((item) => data[item.name]).map(({ id, name }) => ({ id, name }));

        data = {
            ...data,
            agreements,
            expired_date: moment(data.expired_date).format("YYYY-MM-DD"),
            store_online_experience: Boolean(data.store_online_experience),
            personal_title_id: Number(data.personal_title_id),
        };

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (data[key]?.[0]?.size) {
                formData.append(key, data[key][0]);
            } else if (typeof data[key] === "object") {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        });
        dispatch(getRegisterMerchantData(formData));
    };

    const handleSaveDraft = (data) => {
        data = {
            ...data,
            expired_date: moment(data.expired_date).format("YYYY-MM-DD"),
        };

        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                data[key] = "";
            } else if (key === "agreements" || key === "document_url" || key === "document_brand") {
                data[key] = "";
            }
        });

        storage
            .setItem("draft-newmerchant", data)
            .then(() => {
                dispatch(setSnackbar({ open: true, message: "Save as Draft Complete", severity: "info" }));
            })
            .catch((err) => {
                dispatch(setSnackbar({ open: true, message: "Save as Draft Failed", severity: "error" }));
            });
    };

    const handleAreaSelection = (e) => {
        if (e?.id) {
            dispatch(getSubDistrict(e?.id));
            setValue("sub_district_id", null);
        }
    };

    const handleCountrySelection = (e) => {
        if (e?.id) {
            dispatch(getAreaByCountry(e?.id));
            setValue("area_id", null);
        }
    };

    useEffect(() => {
        if (currentValue?.area_id) {
            dispatch(getSubDistrict(currentValue?.area_id.id));
        }
    }, [currentValue]);

    useEffect(() => {
        if (subdistrict) {
            if (
                control.getValues("area_id") !== undefined &&
                subdistrict[0]?.area_id !== control.getValues("area_id")?.id
            ) {
                control.setValue("sub_district_id", null);
                setValue("sub_district_id", null);
            }
        }
    }, [subdistrict]);

    const disablePastDate = (date) => moment(date) <= moment().subtract(1, "day");

    return (
        formReady && (
            <RegisterContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RegisterHead>
                        <RegisterFormLogo>
                            <YHLogo />
                            <TitleText>YOHO Merchant Centre</TitleText>
                        </RegisterFormLogo>
                    </RegisterHead>
                    <RegisterContent>
                        <RegisterFormTitle>New Merchant Registration</RegisterFormTitle>

                        <FormCategory>
                            <FormTitle>Company Information</FormTitle>
                            <InputText
                                control={control}
                                error={!!errors?.company_name_english}
                                helperText={errors?.company_name_english?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="company_name_english"
                                variant="outlined"
                                label="Company Name (English)"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.company_name_chinese}
                                helperText={errors?.company_name_chinese?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="company_name_chinese"
                                variant="outlined"
                                label="Company Name (Chinese)"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.company_email_address}
                                helperText={errors?.company_email_address?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address",
                                    },
                                })}
                                name="company_email_address"
                                variant="outlined"
                                label="Company Email Address"
                                className="full"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.company_website}
                                helperText={errors?.company_website?.message}
                                inputRef={register()}
                                name="company_website"
                                className="full"
                                variant="outlined"
                                label="Company Website"
                            />
                            <InputText
                                multiline
                                control={control}
                                error={!!errors?.company_description}
                                helperText={errors?.company_description?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="company_description"
                                className="full"
                                variant="outlined"
                                label="Company Description"
                            />
                        </FormCategory>

                        <FormCategory>
                            <FormTitle>Business Registration Address</FormTitle>
                            <InputText
                                control={control}
                                error={!!errors?.floor_room}
                                helperText={errors?.floor_room?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="floor_room"
                                className="half"
                                variant="outlined"
                                label="Floor & Room"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.street_building}
                                helperText={errors?.street_building?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="street_building"
                                variant="outlined"
                                label="Street & Building"
                                className="half"
                            />
                            <YohoSelect
                                disabled={true}
                                error={!!errors?.country_id}
                                helperText={errors?.country_id?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="country_id"
                                className="w-3"
                                data={countries}
                                label="Country or City"
                                show={["name"]}
                                action={handleCountrySelection}
                            />
                            <YohoSelect
                                error={!!errors?.area_id}
                                helperText={errors?.area_id?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="area_id"
                                className="w-3"
                                data={areas}
                                label="Area"
                                show={["name"]}
                                action={handleAreaSelection}
                            />
                            <YohoSelect
                                error={!!errors?.sub_district_id}
                                helperText={errors?.sub_district_id?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="sub_district_id"
                                className="w-3"
                                data={subdistrict || default_subdistrict}
                                label="Sub-District"
                                show={["name"]}
                            />
                            <InputText
                                control={control}
                                error={!!errors?.certificate_number}
                                helperText={errors?.certificate_number?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    pattern: {
                                        value: /^[A-Za-z0-9-]*$/g,
                                        message: "This field can only contain alphanumeric and hyphen characters",
                                    },
                                })}
                                name="certificate_number"
                                variant="outlined"
                                label="Business Registration Certificate No."
                            />
                            <YohoDatepicker
                                shouldDisableDate={disablePastDate}
                                inputRef={register()}
                                name="expired_date"
                                label="BR Expire Date"
                                className="half"
                                control={control}
                                defaultValue={"2022-01-01"}
                            />
                            <YohoFileupload
                                inputRef={register({ required: "This field cannot be empty" })}
                                errors={errors}
                                setError={setError}
                                clearErrors={clearErrors}
                                maxSize={5 * 1024 * 1024}
                                name="document_url"
                                className="full"
                            />
                        </FormCategory>
                        <FormCategory>
                            <FormTitle id="ci-title">Contact</FormTitle>
                            <FormControl component="div" className="full Yohoradio" error={!!errors?.personal_title_id}>
                                <FormLabel component="legend">Title</FormLabel>
                                <YohoRadio
                                    data={personal_titles}
                                    control={control}
                                    name="personal_title_id"
                                    rules={{ required: "This field cannot be empty" }}
                                    errors={errors}
                                />
                            </FormControl>
                            <InputText
                                control={control}
                                error={!!errors?.last_name}
                                helperText={errors?.last_name?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="last_name"
                                variant="outlined"
                                label="Last Name"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.first_name}
                                helperText={errors?.first_name?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="first_name"
                                variant="outlined"
                                label="First Name"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.personal_email}
                                helperText={errors?.personal_email?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address",
                                    },
                                })}
                                name="personal_email"
                                className="half"
                                variant="outlined"
                                label="Email Address (for login & application use)"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.personal_position}
                                helperText={errors?.personal_position?.message}
                                inputRef={register({ required: "This field cannot be empty" })}
                                name="personal_position"
                                className="half"
                                variant="outlined"
                                label="Position"
                            />
                            <YohoSelect
                                error={!!errors?.contact_international_code_id}
                                helperText={errors?.contact_international_code_id?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="contact_international_code_id"
                                className="w-3"
                                data={international_codes}
                                show={["dial_code", "name"]}
                                label="International Code"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.contact_number}
                                helperText={errors?.contact_number?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    pattern: {
                                        value: /[0-9]/g,
                                        message: "This field can only contain numeric values",
                                    },
                                })}
                                name="contact_number"
                                className="w-3"
                                variant="outlined"
                                label="Contact Number"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.contact_ext}
                                helperText={errors?.contact_ext?.message}
                                inputRef={register({
                                    pattern: {
                                        value: /[0-9]/g,
                                        message: "This field can only contain numeric values",
                                    },
                                })}
                                name="contact_ext"
                                className="w-3"
                                variant="outlined"
                                label="Ext"
                            />
                            <FormControl
                                component="fieldset"
                                className="full"
                                error={!!errors?.department_ids}
                                style={{ margin: 0 }}
                            >
                                <FormLabel component="legend">Department</FormLabel>
                                <FormGroup aria-label="department_ids">
                                    <YohoCheckbox
                                        data={departments}
                                        name="department_ids"
                                        control={control}
                                        setValue={setValue}
                                        rules={{ required: "This field cannot be empty" }}
                                        errors={errors}
                                        inputRef={register({ required: "This field cannot be empty" })}
                                        setError={setError}
                                        clearErrors={clearErrors}
                                        submitClicked={submitClicked}
                                        setSubmitClicked={setSubmitClicked}
                                    />
                                </FormGroup>
                            </FormControl>
                        </FormCategory>

                        <AdditionalContact
                            contacts={watch("additional_contacts")}
                            setContacts={(contacts) => setValue("additional_contacts", contacts)}
                        />

                        <FormCategory>
                            <FormTitle id="ci-title">Product Category</FormTitle>
                            {categories?.map((category) => {
                                return (
                                    <FormControl
                                        component="fieldset"
                                        className="full"
                                        key={`${category.name}${category.id}`}
                                    >
                                        <FormLabel component="legend">{category.name}</FormLabel>
                                        <FormGroup row aria-label={category.name}>
                                            <YohoCheckbox
                                                data={category.sub_categories}
                                                name={`category_ids.id-${category.id}`}
                                                control={control}
                                                setValue={setValue}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                );
                            })}
                        </FormCategory>

                        <BankField control={control} errors={errors} register={register} />

                        <FormCategory>
                            <FormTitle>Shop Information</FormTitle>
                            <InputText
                                control={control}
                                error={!!errors?.store_name_english}
                                helperText={errors?.store_name_english?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="store_name_english"
                                variant="outlined"
                                label="Store Name (English)"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.store_name_chinese}
                                helperText={errors?.store_name_chinese?.message}
                                inputRef={register({
                                    required: "This field cannot be empty",
                                    validate: checkNoEmoji,
                                })}
                                name="store_name_chinese"
                                variant="outlined"
                                label="Store Name (Traditional Chinese)"
                            />
                            <YohoSelect
                                error={!!errors?.store_product_sales_id}
                                helperText={errors?.store_product_sales_id?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="store_product_sales_id"
                                data={product_sales}
                                variant="outlined"
                                label="No. of Product for Sales"
                                show={["amount_range"]}
                            />
                            <div style={{ width: "100%", marginTop: 10 }}>
                                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                                    Upload list of brands that you want to sell
                                </Typography>
                            </div>
                            <YohoBrandFileUpload
                                inputRef={register({ required: "This field cannot be empty" })}
                                errors={errors}
                                setError={setError}
                                clearErrors={clearErrors}
                                maxSize={5 * 1024 * 1024}
                                name="document_brand"
                                className="full"
                            />
                        </FormCategory>
                        <FormCategory>
                            <FormTitle>Survey</FormTitle>
                            <FormControl component="div" className="full Yohoradio">
                                <FormLabel component="legend">Online Store Experience</FormLabel>
                                <YohoRadio data={online_experience} control={control} name="store_online_experience" />
                            </FormControl>
                            <YohoSelect
                                error={!!errors?.company_size}
                                helperText={errors?.company_size?.message}
                                rules={{ required: "This field cannot be empty" }}
                                control={control}
                                name="company_size"
                                data={optionListNotInOptionsData.company_size}
                                variant="outlined"
                                label="Company Size"
                                show={["size"]}
                            />
                            <InputText
                                control={control}
                                error={!!errors?.revenue_wish_percentage}
                                helperText={errors?.revenue_wish_percentage?.message}
                                inputRef={register({
                                    validate: validateWishPercentage,
                                })}
                                name="revenue_wish_percentage"
                                variant="outlined"
                                label="Percentage (%) of revenue aim to generate through online sales"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                            <InputText
                                control={control}
                                error={!!errors?.online_sales_channels}
                                helperText={errors?.online_sales_channels?.message}
                                inputRef={register()}
                                name="online_sales_channels"
                                variant="outlined"
                                label="Online sales channels"
                                className="full"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.online_sales_channels}
                                helperText={errors?.online_sales_channels?.message}
                                inputRef={register()}
                                name="offline_sales_channels"
                                variant="outlined"
                                label="Offline sales channels"
                                className="full"
                            />
                            <InputText
                                control={control}
                                error={!!errors?.annual_turnover}
                                helperText={errors?.annual_turnover?.message}
                                inputRef={register()}
                                name="annual_turnover"
                                variant="outlined"
                                label="Annual turnover"
                                className="full"
                            />
                        </FormCategory>
                    </RegisterContent>
                    <RegisterFoot>
                        <FormControl component="fieldset" className="form-group">
                            <AgreementFormGroup aria-label="agreement">
                                <FormControlLabel
                                    control={
                                        <Controller
                                            inputRef={register({ required: "This field cannot be empty" })}
                                            name="agreement-1"
                                            render={() => {
                                                return (
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => setValue("agreement-1", e.target.checked)}
                                                        checked={watch("agreement-1")}
                                                    />
                                                );
                                            }}
                                            control={control}
                                            rules={{ required: "This field cannot be empty" }}
                                        />
                                    }
                                    label={Agreement[0].label}
                                />
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="agreement-2"
                                            render={() => {
                                                return (
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => setValue("agreement-2", e.target.checked)}
                                                        checked={watch("agreement-2")}
                                                    />
                                                );
                                            }}
                                            control={control}
                                        />
                                    }
                                    label={Agreement[1].label}
                                />
                            </AgreementFormGroup>
                        </FormControl>
                        <RegisterButtonContainer>
                            <FormButton
                                ccolor="blue"
                                cvariant="outlined"
                                fullWidth
                                onClick={handleSubmit(handleSaveDraft)}
                            >
                                Save draft
                            </FormButton>
                            <FormButton
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                                onClick={handleSubmitted}
                            >
                                Register now
                            </FormButton>
                            <ButtonLink to="/auth/login" cvariant="text" ccolor="blue">
                                Back
                            </ButtonLink>
                        </RegisterButtonContainer>
                    </RegisterFoot>
                </form>
            </RegisterContainer>
        )
    );
}

export default RegisterForm;
