import { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { Form } from "components/General/Form";
import { FieldType } from "components/General/Field";
import { FormButton } from "components/Global";
import { useParams, useLocation, Prompt, useHistory } from "react-router-dom";
import { useApiWithErrorHandle, useShowSnackbar } from "service/apis/merchant/utilHook";
import * as settingAPI from "service/apis/merchant/setting";
import { LoadingContainer } from "components/MerchantCentre/AdministrationConsole/Application/Details/StyledComponent";
import { CircularProgress } from "@material-ui/core";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});
const FormSection = styled("div")({
    flexGrow: 1,
});
const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});
const Grid = styled("div")({
    display: "flex",
});
const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));
const MainContainer = styled("div")({
    width: "100%",
});

const formSections = [
    {
        fields: [
            {
                label: "Banner Name",
                name: "name",
                type: FieldType.TEXT,
            },
            {
                label: "Sort Order",
                name: "sortOrder",
                type: FieldType.TEXT,
            },
            {
                label: "Banner Link",
                name: "linkUrl",
                type: FieldType.TEXT,
                fullWidth: true,
            },
        ],
    },
    {
        label: "Banner Image",
        fields: [
            {
                name: "imageUrl",
                type: FieldType.IMAGE_UPLOAD,
                width: 1330,
                height: 235,
                hints: "The suggested size of this image is 1330 x 235px.",
            },
        ],
    },
    {
        fields: [
            {
                label: "Hide Banner",
                name: "isHidden",
                type: FieldType.SWITCH,
            },
        ],
    },
];

function validateFormData({ name, sortOrder, imageUrl, linkUrl }) {
    if (!name) {
        return ["Banner name must be filled in", { name: "This field cannot be empty" }];
    }
    if (!imageUrl) {
        return ["Banner image must be uploaded", { imageUrl: "This field cannot be empty" }];
    }
    if (linkUrl) {
        const a = document.createElement("a");
        a.href = linkUrl;
        if (a.hostname !== "www.yohohongkong.com" && a.hostname !== "new.beta.yohohongkong.com") {
            return ["Link URL must link to yohohongkong.com", { imageUrl: "URL must link to yohohongkong.com" }];
        }
    }
    if (typeof sortOrder !== "number" && Number.isNaN(Number(sortOrder))) {
        return [
            "Sort order must be filled in and should be number only",
            { sortOrder: "This field cannot be empty and should be number only" },
        ];
    }

    return ["", {}];
}

function processUrl(url) {
    if (!url) return "";
    // Ensure the URL has the correct protocol
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return "https://" + url;
    } else if (url.startsWith("http://")) {
        return "https://" + url.slice(7);
    }
    return url;
}

export default function BannerAddEdit() {
    const { bannerId } = useParams();
    const { pathname } = useLocation();
    const action = pathname.includes("edit") ? "edit" : "add";
    const showSnackbar = useShowSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        name: "",
        linkUrl: "",
        imageUrl: "",
        sortOrder: 0,
        isHidden: false,
    });

    const getStoreBannerDetails = useApiWithErrorHandle(settingAPI.getStoreBannerDetails);
    const createStoreBanner = useApiWithErrorHandle(settingAPI.createStoreBanner);
    const editStoreBanner = useApiWithErrorHandle(settingAPI.editStoreBanner);
    const getUploadPath = useApiWithErrorHandle(settingAPI.getUploadUrl);
    const postUploadImage = useApiWithErrorHandle(settingAPI.postUploadImage);

    const changeHandler = (field, value) => {
        setData({ ...data, [field]: value });
    };

    const handleSubmit = async () => {
        const payload = { ...data, linkUrl: processUrl(data.linkUrl) };

        const [errorMsg, fieldError] = validateFormData(payload);
        if (errorMsg) {
            showSnackbar(errorMsg, "error");
            setErrors(fieldError);
            return;
        }

        setSubmitting(true);
        let result;
        if (action === "add") {
            result = await createStoreBanner(payload);
        } else {
            result = await editStoreBanner(payload);
        }

        if (result) {
            showSnackbar("Saved successfully!", "success");
            history.push("/settings/store-banner");
        }
    };

    formSections[1].fields[0].handleImageUpload = async (imageFile) => {
        const uploadPath = await getUploadPath();
        if (uploadPath) {
            const formData = new FormData();
            formData.append("image", imageFile);
            const result = await postUploadImage({ uploadPath, formData });
            return result?.publicUrl;
        }
    };

    useEffect(() => {
        const loadInitialData = async () => {
            const bannerData = await getStoreBannerDetails(bannerId);
            setData(bannerData);
            setLoading(false);
        };

        if (action === "add") {
            setLoading(false);
        } else {
            loadInitialData();
        }
    }, []);

    if (loading)
        return (
            <LoadingContainer>
                <CircularProgress size={100} />
                Loading Category Information
            </LoadingContainer>
        );

    return (
        <PageContainer>
            <h1>Banner Details</h1>
            <Grid>
                <MainContainer>
                    <FormSection>
                        <Form
                            sections={formSections}
                            values={data}
                            errors={errors}
                            defaultChangeHandler={changeHandler}
                        />
                    </FormSection>
                </MainContainer>

                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Save"}
                        </FormButton>
                        <FormButton
                            cvariant="text"
                            ccolor="blue"
                            onClick={() => history.push("/settings/store-banner")}
                        >
                            Discard Changes
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt when={!submitting} message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}
