import {
    CircularProgress,
    FormControl,
    FormGroup,
    FormLabel,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
    FormButton,
    FormCategory,
    FormTitle,
    YohoCheckbox,
    YohoDatepicker,
    YohoRadio,
    YohoSelect,
} from "components/Global";
import { FormText } from "components/Global/Form";
import InputText from "components/Global/Form/InputText";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import { encryptString } from "helper/transformer";
import usePopup from "hooks/usePopup";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { getApplicationApprove, getApplicationDeny } from "service/actions/admin";
import { setRedirect } from "service/actions/site";
import { useAdminApplicationActions, useGetMerhcantApplication, useGetCommission } from "../hooks";
import { Agreement, default_subdistrict, defaultOptionsData, yesNoOption } from "./constant";
import { ApprovedPopup, DenyPopup } from "./popup";
import {
    AgreementFormGroup,
    CommissionContainer,
    FormWrapper,
    LoadingContainer,
    RegisterContainer,
    RegisterContent,
    RegisterFoot,
    RegisterFormTitle,
    RegisterTitle,
    SideMenu,
    SmallButton,
} from "./StyledComponent";
import BankField, { validateBankField } from "components/Global/Form/BankField";
import AdditionalContact from "components/Global/Form/AdditionalContact";
import { validateEmail } from "helper/validate";

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
        },
    },
    warnMessage: {
        marginTop: 12,
        color: theme.yoho.md_color.red[500],
        fontWeight: "bold",
        fontSize: 15,
    },
}));

const BrandSection = styled("div")({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100% !important",
});

const Button = styled("a")({
    width: "100px",
    height: "44px",
    borderRadius: "26px",
    border: "1px solid #00ACEE",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    color: "#00ACEE",
    cursor: "pointer",
    transition: "all 0.3s ease",
    marginLeft: 20,
    textDecoration: "none",
    "&:hover": {
        backgroundColor: "#E1F5FD",
    },
    "&:active": {
        backgroundColor: "#00ACEE",
        color: "white",
    },
    "& > input": {
        display: "none",
    },
});

const $allcategories = createSelector(
    (state) => state.site.master.category,
    (maincategory) => {
        return maincategory.reduce((acc, curr) => {
            return [...acc, ...curr.sub_categories];
        }, []);
    },
);

function commisisonReducer(state, action) {
    const index = state.findIndex((obj) => obj.category_id === action.category_id);
    if (index === -1) return [...state, action];

    let newState = [...state];
    newState[index] = action;

    return newState;
}

function categoryReducer(state, action) {
    return action;
}

// Remove data that does not need to sent to server on submit
function clean(applicationResult) {
    let result = JSON.parse(JSON.stringify(applicationResult));
    delete result.document_url;
    delete result.document_filename;
    delete result.document_brand;
    delete result.document_brand_filename;
    delete result.image_url;
    delete result.image_file;
    delete result.deleted_at;
    delete result.updated_at;
    delete result.remarks_to_applicant;
    delete result.reason_of_declined;
    delete result.hide_store_page;
    delete result.store_product_sales_id;
    delete result.office_time_start;
    delete result.office_time_end;
    delete result.category_ids;
    delete result.area_ids;
    delete result.agreements;
    delete result.company_size;
    delete result.revenue_wish_percentage;
    delete result.online_sales_channels;
    delete result.offline_sales_channels;
    delete result.annual_turnover;
    return result;
}

function validateForm(formData) {
    if (!formData.company_name_english) return "Company English name cannot be blank";

    if (!formData.company_name_chinese) return "Company Chinese name cannot be blank";

    if (!validateEmail(formData.user.personal_email)) return "Invalid contact email";

    if (!formData.store_name_english || !formData.store_name_chinese) return "Store name cannot be empty";

    const { message } = validateBankField({
        accountInfo: formData,
        mandatory: false,
    });
    if (message) return message;

    return "";
}

// TO-DO: Redux option list cannot figure out, if found can be combined
// Option "Unspecified" for old company with do not contain
const optionListNotInOptionsData = {
    company_size: [
        { id: 1, size: "1-24" },
        { id: 2, size: "25-49" },
        { id: 3, size: "50-99" },
        { id: 4, size: "100 or above" },
        { id: 5, size: "unspecified" },
    ],
};

function ApplicationDetails() {
    const optionsData = useSelector((state) => state.site.master);
    const categorydata = useSelector((state) => $allcategories(state));
    const dispatch = useDispatch();
    const popup = usePopup();
    const classes = useStyles();
    const { register, setValue, control, reset } = useForm();
    const [formReady, setFormReady] = useState(false);
    const { id } = useParams();
    const [applicationId, companyId] = encryptString(id, "ascii").split(",");
    const { isLoading, data } = useGetMerhcantApplication(applicationId);
    const { isLoading: commissionLoading, data: commissionData } = useGetCommission(companyId);

    const applicationData = data?.result || {};
    const [status, setStatus] = useState(null);
    const [commisisonState, dispatchCommisison] = useReducer(commisisonReducer, []);
    const [categoryState, dispatchCategory] = useReducer(categoryReducer, applicationData.category_ids);
    const { updateCommission, updateCategory, onUpdatecommisison, onUpdateCategory } = useAdminApplicationActions();
    const [validationError, setValidationError] = useState("");

    const storecommisison = useCallback(() => {
        let localState = {
            company_id: companyId,
            commissions: commisisonState,
        };
        updateCommission(localState);
    }, [commisisonState]);

    const storeCategory = useCallback(() => {
        let localState = {
            company_id: companyId,
            category_ids: categoryState,
        };
        updateCategory(localState);
    }, [categoryState]);

    useEffect(() => {
        if (!isLoading) {
            if (!applicationData.company_size) {
                applicationData.company_size = "unspecified";
            }
            reset(applicationData);
            setStatus({ status: applicationData.status });
            setFormReady(true);
        }
    }, [isLoading]);

    const {
        areas,
        countries,
        personal_titles,
        international_codes,
        departments,
        categories,
        subdistrict,
        product_sales,
    } = Object.keys(optionsData).length > 0 ? optionsData : defaultOptionsData;

    return !formReady ? (
        <LoadingContainer>
            <CircularProgress size={100} />
            Loading Form Data
        </LoadingContainer>
    ) : (
        <RegisterContainer>
            <RegisterTitle>
                <RegisterFormTitle>
                    <FormText>
                        <h2>Application Details</h2>
                    </FormText>
                    <FormText>
                        <p className="body1">
                            Application# <strong>{applicationData.application_id}</strong>
                        </p>
                    </FormText>
                </RegisterFormTitle>
                <FormText>
                    <p className="body1">
                        Submission Date{" "}
                        <strong>{moment(applicationData.submission_date).format("MMM DD, YYYY hh:mm")}</strong>
                    </p>
                </FormText>
            </RegisterTitle>
            <FormWrapper>
                <form>
                    <RegisterContent>
                        <FormCategory>
                            <FormTitle>Company Information</FormTitle>
                            <InputText
                                control={control}
                                name="company_name_english"
                                variant="outlined"
                                label="Company Name (English)"
                            />
                            <InputText
                                control={control}
                                name="company_name_chinese"
                                variant="outlined"
                                label="Company Name (Chinese)"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="company_email_address"
                                className="full"
                                variant="outlined"
                                label="Company Email Address"
                            />
                            <InputText
                                control={control}
                                name="company_website"
                                className="full"
                                variant="outlined"
                                label="Company Website"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="company_description"
                                className="full"
                                variant="outlined"
                                label="Company Description"
                            />
                        </FormCategory>

                        <FormCategory>
                            <FormTitle>Business Registration Address</FormTitle>
                            <InputText
                                disabled={true}
                                control={control}
                                name="floor_room"
                                className="half"
                                variant="outlined"
                                label="Floor & Room"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="street_building"
                                className="half"
                                variant="outlined"
                                label="Street & Building"
                            />
                            <YohoSelect
                                disabled={true}
                                inputRef={register()}
                                control={control}
                                name="area_id"
                                className="half"
                                data={areas}
                                label="Area"
                                show={["name"]}
                            />
                            <YohoSelect
                                disabled={true}
                                inputRef={register()}
                                control={control}
                                name="sub_district_id"
                                className="half"
                                data={subdistrict || default_subdistrict}
                                label="Sub-District"
                                show={["name"]}
                            />
                            <YohoSelect
                                disabled={true}
                                inputRef={register()}
                                control={control}
                                name="country_id"
                                className="w-3"
                                data={countries}
                                label="Country or City"
                                show={["name"]}
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="certificate_number"
                                className="w-3"
                                variant="outlined"
                                label="Business Registration Certificate No."
                            />
                            <YohoDatepicker
                                disabled={true}
                                inputRef={register()}
                                name="expired_date"
                                label="BR Expire Date"
                                control={control}
                                defaultValue={"2022-01-01"}
                                className="w-3"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="document_url.url"
                                label="Document URL"
                                className="full"
                            />
                        </FormCategory>

                        <FormCategory>
                            <FormTitle>Contact</FormTitle>
                            <FormControl component="div" className="full Yohoradio">
                                <FormLabel component="legend">Title</FormLabel>
                                <YohoRadio
                                    disabled={true}
                                    data={personal_titles}
                                    control={control}
                                    name="user.personal_title_id"
                                />
                            </FormControl>
                            <InputText
                                disabled={true}
                                control={control}
                                name="user.last_name"
                                variant="outlined"
                                label="Last Name"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="user.first_name"
                                variant="outlined"
                                label="First Name"
                            />
                            <InputText
                                control={control}
                                name="user.personal_email"
                                className="half"
                                variant="outlined"
                                label="Email Address"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="user.personal_position"
                                className="half"
                                variant="outlined"
                                label="Position"
                            />
                            <YohoSelect
                                disabled={true}
                                inputRef={register()}
                                control={control}
                                name="user.contact_international_code_id"
                                className="w-3"
                                data={international_codes}
                                show={["dial_code", "name"]}
                                label="International Code"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="user.contact_number"
                                className="w-3"
                                variant="outlined"
                                label="Contact Number"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="user.contact_ext"
                                className="w-3"
                                variant="outlined"
                                label="Ext"
                            />
                            <FormControl component="fieldset" className="full" style={{ margin: 0 }}>
                                <FormLabel component="legend">Department</FormLabel>
                                <FormGroup aria-label="department_ids">
                                    <YohoCheckbox
                                        disabled={true}
                                        data={departments}
                                        name="user.department_ids"
                                        control={control}
                                        setValue={setValue}
                                        detail={true}
                                    />
                                </FormGroup>
                            </FormControl>
                        </FormCategory>

                        <AdditionalContact contacts={applicationData.additional_contacts} readOnly />

                        <FormCategory>
                            <FormTitle>Product Category</FormTitle>
                            {categories.map((category) => (
                                <FormControl
                                    component="fieldset"
                                    className="full"
                                    key={`${category.name}${category.id}`}
                                >
                                    <FormLabel component="legend">{category.name}</FormLabel>
                                    <FormGroup row aria-label={category.name}>
                                        <YohoCheckbox
                                            data={category.sub_categories}
                                            name={`category_ids.id-${category.id}`}
                                            control={control}
                                            setValue={(cat, subcat) => {
                                                dispatchCategory({
                                                    ...applicationData.category_ids,
                                                    ...categoryState,
                                                    [cat.split(".")[1]]: subcat,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </FormControl>
                            ))}
                            <SmallButton onClick={storeCategory} disabled={onUpdateCategory}>
                                Save changes
                            </SmallButton>
                        </FormCategory>
                        <FormCategory>
                            <CommissionContainer>
                                {commissionLoading ? (
                                    <Skeleton style={{ width: "100%" }} />
                                ) : (
                                    <>
                                        <div className="title">Selected Category</div>
                                        <div className="title right">Commission Rate</div>
                                        {commissionData.result.map((commisison) => {
                                            const category = categorydata.find(
                                                (subcat) => subcat.id === commisison.cat_id,
                                            );
                                            return (
                                                <React.Fragment key={commisison.cat_id}>
                                                    <div className="field">{category?.name}</div>
                                                    <div className="value right">
                                                        <TextField
                                                            defaultValue={commisison.commission_rate}
                                                            type="number"
                                                            onChange={(event) => {
                                                                dispatchCommisison({
                                                                    category_id: commisison.cat_id,
                                                                    commission:
                                                                        event?.target?.value === ""
                                                                            ? 0
                                                                            : parseInt(event?.target?.value),
                                                                });
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.inputStyle,
                                                                },
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 100,
                                                                },
                                                                style: { color: "red", paddingLeft: "6px" },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">%</InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )}
                                <SmallButton onClick={storecommisison} disabled={onUpdatecommisison}>
                                    Save changes
                                </SmallButton>
                            </CommissionContainer>
                        </FormCategory>
                        <BankField control={control} />
                        <FormCategory>
                            <FormTitle>Shop Information</FormTitle>
                            <InputText
                                control={control}
                                name="store_name_english"
                                variant="outlined"
                                label="Store Name (English)"
                            />
                            <InputText
                                control={control}
                                name="store_name_chinese"
                                variant="outlined"
                                label="Store Name (Traditional Chinese)"
                            />
                            <YohoSelect
                                inputRef={register()}
                                disabled={true}
                                control={control}
                                name="store_product_sales_id"
                                data={product_sales}
                                ariant="outlined"
                                label="No. of Product for Sales"
                                show={["amount_range"]}
                            />
                            <FormControl component="div" className="Yohoradio">
                                <FormLabel component="legend" style={{ width: 220 }}>
                                    Show Flagship Logo
                                </FormLabel>
                                <YohoRadio data={yesNoOption} control={control} name="show_flagship_logo" />
                            </FormControl>
                            <FormControl component="div" className="Yohoradio">
                                <FormLabel component="legend" style={{ width: 220 }}>
                                    Price Guarantee
                                </FormLabel>
                                <YohoRadio data={yesNoOption} control={control} name="price_guarantee" />
                            </FormControl>
                            <FormControl component="div" className="Yohoradio">
                                <FormLabel component="legend" style={{ width: 220 }}>
                                    Enable Points
                                </FormLabel>
                                <YohoRadio data={yesNoOption} control={control} name="is_point_enable" />
                            </FormControl>
                            <BrandSection>
                                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                                    List of brands
                                </Typography>
                                {applicationData.document_brand?.url ? (
                                    <Button
                                        href={applicationData?.result?.document_brand?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View
                                    </Button>
                                ) : (
                                    <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
                                        -
                                    </Typography>
                                )}
                            </BrandSection>
                        </FormCategory>
                        <FormCategory>
                            <FormTitle>Survey</FormTitle>
                            <FormControl component="div" className="Yohoradio">
                                <FormLabel component="legend" style={{ width: 220 }}>
                                    Online Store Experience
                                </FormLabel>
                                <YohoRadio
                                    disabled={true}
                                    data={yesNoOption}
                                    control={control}
                                    name="store_online_experience"
                                />
                            </FormControl>
                            <YohoSelect
                                disabled={true}
                                control={control}
                                name="company_size"
                                data={optionListNotInOptionsData.company_size}
                                variant="outlined"
                                label="Company Size"
                                show={["size"]}
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="revenue_wish_percentage"
                                variant="outlined"
                                label="Revenue aim to generate"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="online_sales_channels"
                                variant="outlined"
                                label="Online sales channels"
                                className="full"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="offline_sales_channels"
                                variant="outlined"
                                label="Offline sales channels"
                                className="full"
                            />
                            <InputText
                                disabled={true}
                                control={control}
                                name="annual_turnover"
                                variant="outlined"
                                label="Annual turnover"
                                className="full"
                            />
                        </FormCategory>
                    </RegisterContent>
                    <RegisterFoot>
                        <FormControl component="fieldset" className="form-group">
                            <AgreementFormGroup aria-label="agreement">
                                <YohoCheckbox
                                    disabled={true}
                                    data={Agreement}
                                    name="agreements"
                                    control={control}
                                    setValue={setValue}
                                />
                            </AgreementFormGroup>
                        </FormControl>
                    </RegisterFoot>
                </form>
                <SideMenu>
                    <FormText>
                        <h5>Application Status</h5>
                    </FormText>
                    <UncontrolledNormalSelect
                        variant="outlined"
                        data={[
                            ...(applicationData.status === "pending" ? [{ name: "Pending", value: "pending" }] : []),
                            { name: "Approved", value: "approved" },
                            { name: "Declined", value: "declined" },
                        ]}
                        name="status"
                        onChange={setStatus}
                        value={status.status || status}
                        defaultValue={applicationData.status}
                    />
                    <FormText>
                        <p className="body2">
                            Last update:{" "}
                            <strong>{moment(applicationData.updated_at).format("MMM DD, YYYY hh:mm")}</strong>
                        </p>
                        {!!validationError && <div className={classes.warnMessage}>{validationError}</div>}
                    </FormText>
                    <FormButton
                        cvariant="contained"
                        ccolor="blue"
                        onClick={() => {
                            const data = clean(control.getValues());
                            const errorMessage = validateForm(data);
                            if (errorMessage) {
                                setValidationError(errorMessage);
                                return;
                            }
                            if (status.status.toLowerCase() === "declined") {
                                popup(DenyPopup, {
                                    action: (remarks) => {
                                        dispatch(
                                            getApplicationDeny({
                                                ...remarks,
                                                callback_url: `${window.location.origin}/application/revise/${Buffer.from(applicationData.application_id).toString("base64")}`,
                                                company_id: applicationData.user.company_id,
                                                status: "declined",
                                                data,
                                            }),
                                        );
                                    },
                                });
                            } else if (status.status.toLowerCase() === "approved") {
                                popup(ApprovedPopup, {
                                    data: control.getValues(),
                                    action: () => {
                                        dispatch(
                                            getApplicationApprove({
                                                callback_url: `${window.location.origin}/auth/login`,
                                                company_id: applicationData.user.company_id,
                                                status: "approved",
                                                data,
                                            }),
                                        );
                                    },
                                });
                            } else {
                                dispatch(
                                    getApplicationApprove({
                                        company_id: applicationData.user.company_id,
                                        status: "pending",
                                        data,
                                    }),
                                );
                            }
                        }}
                    >
                        Save and Finish
                    </FormButton>
                    <FormButton
                        cvariant="text"
                        ccolor="blue"
                        onClick={() => dispatch(setRedirect({ pathname: "/admin/application" }))}
                    >
                        Discard
                    </FormButton>
                </SideMenu>
            </FormWrapper>
        </RegisterContainer>
    );
}

export default ApplicationDetails;
