import { Icon, styled } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { FormButton, FormText } from "components/Global/Form";
import { useHistory } from "react-router-dom";
import { convertOrderStatus } from "../../helper";
import { useOrderActions } from "../../hooks";

const IconStyle = {
    textAlign: "center",
    height: 16,
    width: 16,
    fontSize: "unset",
};
const ImgStyle = { height: 16 };

const CustomButton = styled(FormButton)({
    height: 36,
    width: "fit-content",
});

export const OrderDetailsLabel = styled("div")(({ theme, color, code }) => ({
    display: "flex",
    borderRadius: 4,
    ...(color && code && { background: theme.yoho.md_color[color][code] }),
    padding: "5px 8px",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    minWidth: "fit-content",
}));

const OrderHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    width: "100%",
});

const OrderActionButton = styled("div")({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
});

const OrderLabels = ({ status, ...rest }) => {
    const { downloadOrderInvoice, orderInvoiceLoading } = useOrderActions();

    return {
        "To Ship": (
            <OrderHeader>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-checked.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Paid</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="red" code={50}>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-package.svg" />
                    </Icon>
                    <FormText color="red" code={500}>
                        <p className="subtitle3">To Ship</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderActionButton>
                    <CustomButton
                        ccolor="grey"
                        cvariant="outlined"
                        disabled={orderInvoiceLoading}
                        onClick={() => {
                            downloadOrderInvoice({
                                order_id: rest.data.order_id,
                                order_sn: rest.data.order_sn,
                            });
                        }}
                    >
                        <FormText color="grey" code={800}>
                            <p className="subtitle3">{orderInvoiceLoading ? "Downloading..." : "Download invoice"}</p>
                        </FormText>
                    </CustomButton>
                </OrderActionButton>
            </OrderHeader>
        ),
        Shipped: (
            <OrderHeader>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-checked.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Paid</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="grey" code={200}>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-truck.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Shipped</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderActionButton>
                    <CustomButton
                        ccolor="grey"
                        cvariant="outlined"
                        disabled={orderInvoiceLoading}
                        onClick={() => {
                            downloadOrderInvoice({
                                order_id: rest.data.order_id,
                                order_sn: rest.data.order_sn,
                            });
                        }}
                    >
                        <FormText color="grey" code={800}>
                            <p className="subtitle3">{orderInvoiceLoading ? "Downloading..." : "Download invoice"}</p>
                        </FormText>
                    </CustomButton>
                </OrderActionButton>
            </OrderHeader>
        ),
        Unpaid: (
            <>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-unchecked.svg" />
                    </Icon>
                    <FormText color="grey" code={500}>
                        <p className="subtitle3">Unpaid</p>
                    </FormText>
                </OrderDetailsLabel>
            </>
        ),
        Cancelled: (
            <>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-cancelled.svg" />
                    </Icon>
                    <FormText color="grey" code={500}>
                        <p className="subtitle3">Cancelled</p>
                    </FormText>
                </OrderDetailsLabel>
            </>
        ),
        "Order refund": (
            <>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-checked.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Paid</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="grey" code={200}>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-truck.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Shipped</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="red" code={50}>
                    <FormText color="red" code={500}>
                        <p className="subtitle3">Case opened</p>
                    </FormText>
                </OrderDetailsLabel>
            </>
        ),
        "Case closed": (
            <>
                <OrderDetailsLabel>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-checked.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Paid</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="grey" code={200}>
                    <Icon color="black" style={IconStyle}>
                        <img style={ImgStyle} src="/assets/icon/product-truck.svg" />
                    </Icon>
                    <FormText color="grey" code={800}>
                        <p className="subtitle3">Shipped</p>
                    </FormText>
                </OrderDetailsLabel>
                <OrderDetailsLabel color="green" code={50}>
                    <FormText color="green" code={500}>
                        <p className="subtitle3">Case closed</p>
                    </FormText>
                </OrderDetailsLabel>
            </>
        ),
    }[status];
};

export default ({ data }) => {
    const history = useHistory();

    const collections = {
        order_status: data?.order_status,
        pay_status: data?.pay_status,
        shipping_status: data?.shipping_status,
        RefundRequests: data?.RefundRequests,
    };
    return (
        <div className="head">
            <ArrowBackIos onClick={() => history.replace("/orders")} style={{ cursor: "pointer" }} />
            <FormText style={{ minWidth: "fit-content" }}>
                <h2>Order# {data?.order_sn}</h2>
            </FormText>
            <OrderLabels status={convertOrderStatus(collections)} data={data} />
        </div>
    );
};
